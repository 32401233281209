.howtouse {
  min-width: 320px;
  width: 50%;
  margin: 0 auto;
  list-style: none;
  background: #e7d0a9;
}
.how_title {
  padding: 24px;
}
.description-list {
  text-align: left;
  font-size: 21px;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 40px;
}
.description-list ul {
  padding-top: 20px;
}
.description-list > ul > li {
  padding-bottom: 25px;
}
.footer_space {
  height: 80px;
}
