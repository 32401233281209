.overlay {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
}
.createThreadInput {
  width: 300px;
  position: absolute;
  top: 40%;
  height: 50px;
  left: calc(50% - 154px);
  font-size: 24px;
}
.createThreadBtn {
  position: absolute;
  top: 55%;
  left: calc(50% - 48px);
}
.close-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 30px;
}
.error-title {
  position: absolute;
  font-size: 20px;
  top: calc(40% - 65px);
  left: calc(50% - 204px);
  color: white;
  font-weight: 700;
}
