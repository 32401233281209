.tweet {
  min-width: 300px;
  width: 50%;
  margin: 0 auto;
  text-align: left;
  border-bottom: 1px solid black;
}
.tweet-content {
  font-size: 21px;
  padding-left: 10px;
}
.tweet-name {
  padding-right: 15px;
}
.params_title {
  margin-top: 45px;
}
.btnToTop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 60px;
}
