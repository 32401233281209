.scroll_content {
  display: flex;
  max-width: 80%;
  overflow-x: auto;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  margin-bottom: 20px;
}
.thread-title {
  height: 45px;
}
.scroll_content li {
  padding: 8px;
  margin: 3px;
  flex-shrink: 0;
  list-style: none;
}
.scroll_content::-webkit-scrollbar {
  height: 12px; /* スクロールバーの高さ */
}

.scroll_content::-webkit-scrollbar-thumb {
  background: #aaa; /* ツマミの色 */
  border-radius: 6px; /* ツマミ両端の丸み */
}

.scroll_content::-webkit-scrollbar-track {
  background: #ddd; /* トラックの色 */
  border-radius: 6px; /* トラック両端の丸み */
}
.create_thread {
  margin: 30px;
}
