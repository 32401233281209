.App {
  text-align: center;
}
a {
  color: #fff;
  transition: 0.3s;
  text-decoration: none;
}
a:hover {
  opacity: 0.7;
}

.header {
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #212121;
  z-index: 999;
}
.header_inner {
  padding: 0 2%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1000;
}
.header_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header_left a {
  text-decoration: none;
}
.header_ttl {
  width: 145px;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header_left img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}
.header_right {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: bold;
}
.header_user {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 13px;
}
.signup_btn {
  margin: 0;
  margin-top: 0;
  text-align: center;
}
.signup_btnlink {
  height: 100%;
  padding: 0.7em 2em;
  border-radius: 3px;
  background: #39b4c9;
  color: #141414;
  font-weight: bold;
}

.header_right {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-wrapper {
  display: block;
  margin: 0px auto;
  max-width: 760px;
  min-height: 484px;
  position: relative;
}
.form-item {
  display: block;
  margin: auto;
  position: absolute;
  width: 100%;
}

input[type="radio"] + label:before {
  border: 3px dashed #ccc;
  box-sizing: border-box;
  content: "";
  display: inline-block;
  background-size: contain;
  width: 340px;
  height: 218px;
  margin: 10px 20px;
  cursor: pointer;
}
input[type="radio"] + label::before:hover {
  border: 3px solid #ccc;
  transform: translateY(-3px);
}
input[type="radio"]:checked + label::before {
  border: 3px solid #39b4c9;
  transition: 100ms;
  border-radius: 6px;
  box-shadow: 5px 5px 5px #eee;
}
input[type="radio"] {
  display: none;
}

input[type="radio"][value="Binance"] + label:before {
  background-image: url(https://coinback-crypto.com/wp-content/uploads/2022/07/binance.webp);
}
input[type="radio"][value="OKX"] + label:before {
  background-image: url(https://coinback-crypto.com/wp-content/uploads/2022/07/okxwebp.webp);
}
input[type="radio"][value="MEXC Global"] + label:before {
  background-image: url(https://coinback-crypto.com/wp-content/uploads/2022/07/mexc.webp);
}
input[type="radio"][value="Bybit"] + label:before {
  background-image: url(https://coinback-crypto.com/wp-content/uploads/2022/07/bybit.webp);
}

.dropzone {
  height: 340px;
  width: 680px;
  border: dashed 3px #ccc;
  margin: 19px 37px;
  margin-top: 39px;
  z-index: 2;
  cursor: pointer;
}

button {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #fff;
  background-color: #39b4c9;
  border-radius: 0.5rem;
  border: none;
  border-bottom: 3px solid #298190;
}
button:active {
  border-bottom: 0;
  transform: translateY(5px);
}

.submit-area {
  padding: 161px 0;
}

.result-item {
  position: absolute;
  width: 760px;
  padding: 130px;
  left: 50%;
  transform: translateX(-50%);
}

.message {
  margin-top: 100px;
}

.lets-reload {
  color: #555;
  font-size: 0.9em;
}
.twitter-icon {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  width: 1.28571429em;
  text-align: center;
}
.twitter-icon::before {
  content: "";
  background-color: #fff;
  -webkit-mask: url("../../public/twitter.svg");
  mask: url("../../public/twitter.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
}
.tweet-btn {
  margin-top: 20px;
  display: inline-block;
  font-weight: bold;
  padding: 0.8em 1.6em;
  margin: 0.4em;
  background-color: #00acee;
  color: #fff;
  text-decoration: none;
  border-style: none;
  border-radius: 5px;
}
.tweet-btn:hover {
  transition: 0.5s;
  transform: translateY(-2px);
  box-shadow: 1px 2px 3px #aaa;
}

.explain-wrapper {
  margin: auto;
  margin-top: 50px;
  max-width: 900px;
}
.explain-head {
  font-size: 19px;
}
.explain-items {
  display: flex;
  margin: auto;
}
.explain-item {
  text-align: center;
  margin: 10px auto;
  max-width: 300px;
  width: 30%;
}
.explain-item h3 {
  margin: 0.5em 0;
}
.explain-item p {
  text-align: start;
}
.explain-img {
  margin: 10px auto;
  width: 120px;
  height: 120px;
}

.howto {
  color: #141414;
}
.howto a {
  color: #007eb0;
}
