.App {
  text-align: center;
}
a {
  color: #fff;
  transition: 0.3s;
  text-decoration: none;
}
a:hover {
  color: #eee;
}
.header {
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #212121;
  z-index: 9;
}
.header_inner {
  width: 100vw;
  padding: 0 2%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header_left a {
  text-decoration: none;
}
.header_ttl {
  width: 145px;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header_left img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}
.header_right {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: bold;
}
.header_user {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: bold;
}
.signup_btn {
  margin: 0;
  margin-top: 0;
  text-align: center;
}
.signup_btnlink {
  height: 100%;
  padding: 0.7em 2em;
  border-radius: 3px;
  background: #39b4c9;
  color: #141414;
  font-weight: bold;
}

.header_right {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header_space {
  height: 100px;
}
.header_nav > ul {
  display: flex;
  list-style: none;
  padding: 0;
}
.header_nav > ul > a > li {
  margin-right: 25px;
}
.header_nav > ul > a > li:hover {
  opacity: 0.6;
  cursor: pointer;
}
