header {
  display: flex;
  height: 100px;
  justify-content: space-between;
}
header > img {
  padding: 10px;
}
header > nav > ul {
  list-style: none;
  display: flex;
}
header > nav > ul > li {
  padding: 10px;
}

.thread {
  max-width: 1100px;
  width: 98%;
  margin: 0 auto;
}
.tweet {
  position: relative;
}
.tweet-info {
}
.tweet-reactions {
  display: flex;
}
.tweet-reactions svg {
  width: 30px;
  height: 30px;
}
.reply {
  margin-left: 30px;
}
.reply-info {
}
.reply-reactions {
  display: flex;
}
.reply-reactions svg {
  width: 30px;
  height: 30px;
}
footer {
  background: gray;
  height: 90px;
  width: 100vw;
}
footer ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}
footer p {
  text-align: center;
}
.thread-title {
  margin: 0 10px;
}
.thread-title:hover {
  background: aqua;
}
