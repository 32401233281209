.form-post {
  min-width: 300px;
  width: 50%;
  margin: 0 auto;
  margin-top: 30px;
}
.form-username {
  width: 120px;
  height: 30px;
  margin-left: 20px;
}
.username-wrapper {
  text-align: left;
}
.form-usercontents {
  width: 80%;
  margin: 0 auto;
  height: 80px;
}
